.about-container {
  padding: 8rem 0rem 0rem 0rem !important;
}

.text-section {
  padding-right: 2rem;
}

.about-header-img {
  border-radius: 25px;
}

.first-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #131313;
  margin-bottom: 1rem;
}

.first-description {
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

.second-section {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.service-content {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer !important;
}

.service-title,
.reason-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 2rem !important;
}

.service-description,
.reason-description {
  font-size: 1rem;
  color: #555;
}

.service-icon,
.reason-icon {
  font-size: 3rem;
  color: #007bff;
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: auto; /* Permite rolagem horizontal */
  padding-bottom: 20px;
  gap: 16px; /* Espaçamento entre os cards */
  scroll-behavior: smooth; /* Rolagem suave */
  overflow-x: scroll;
  -ms-overflow-style: none; /* Para IE e Edge */
  scrollbar-width: none; /* Para Firefox */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari e Opera */
}

.horizontal-scroll-container::-webkit-scrollbar {
  height: 8px; /* Define o tamanho da barra de rolagem */
}

.cta-btn-contato {
  background-color: transparent !important;
  color: #617DF8 !important;
  font-weight: 600 !important;
  border-radius: 11px !important;
  font-size: 1.1rem !important;
  border: #617DF8 solid 2px !important;
  transition: 300ms !important;
  padding: 1rem 2rem !important;
  margin-top: 1rem !important;
  display: flex;
  text-align: left;
  justify-content: space-between !important;
  align-items: center !important;
}

.cta-btn-contato:hover {
  transform: scale(1.1);
  color: #f5f5f5 !important;
  background-color: #007bff !important;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* Cor da barra de rolagem */
  border-radius: 4px; /* Bordas arredondadas */
}

.service-content {
  min-width: 35% !important; /* Define a largura mínima dos cards */
  max-width: 35% !important; /* Define a largura máxima para os cards */
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.service-icon,
.reason-icon {
  font-size: 2.5rem;
  color: #007bff;
}

.horizontal-scroll-container > div {
  flex-shrink: 0; /* Garante que os itens não encolham e fiquem lado a lado */
  margin-right: 16px;
}

.title-cta-reasons {
    text-align: center;
    font-weight: 600;
    font-size: 1.9rem !important;
    margin-top: 7rem !important;
    margin-bottom: 1rem !important;
    color: #617DF8 !important;
}

.desc-cta-reasons{
    text-align: center;
    font-weight: 500;
    margin-bottom: 5rem !important;
}

.reasons-row {
    padding-bottom: 3rem !important;
}

.card-reasons {
    border-radius: 20px !important;
    border: #bebebe solid 1px !important;
    box-shadow: none !important;
    height: 400px !important;
}

@media (max-width: 768px) {
  .horizontal-scroll-container {
    padding: 2rem 2rem 0 2rem !important;
    flex-direction: row;
    overflow-x: scroll; /* Para mobile, o usuário rola horizontalmente */
  }

  .service-content {
    min-width: 80% !important; /* Define a largura mínima dos cards */
    max-width: 90% !important; /* Define a largura máxima para os cards */
  }
}

/* Estilo para desativar a seleção de texto no contêiner */
.horizontal-scroll-container {
  user-select: none; /* Impede seleção de texto */
  -webkit-user-select: none; /* Para compatibilidade com Safari */
  -moz-user-select: none; /* Para compatibilidade com Firefox */
  -ms-user-select: none; /* Para compatibilidade com IE */
}

.tip-exp {
  padding: 2rem 3rem !important;
}

.title-exp {
  font-weight: 600;
  margin-bottom: 1rem;
  color: #617DF8 !important;
}

.tip-row {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.description-exp {
  font-weight: 500;
  font-size: 0.9rem;
  text-align: justify;
  padding: 1rem 0;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-placeholder {
  background-color: #b0b0b0;
  width: 100%;
  height: 300px;
  border-radius: 12px;
}

.second-section {
  padding: 2rem 12rem !important;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #617df8;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.image-placeholder {
  border-radius: 12px;
}

.image-placeholder.small {
  width: 100%;
  height: 150px;
}

.image-placeholder.large {
  width: 100%;
  height: 300px;
}

.third-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.space-col {
  padding: 40px 20px;
  color: #131313;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.space-icon {
  margin-bottom: 20px;
}

.space-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.space-text {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

.about-section-wrapper {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* Responsividade para tablets (telas médias) */
@media (max-width: 991px) {
  .first-section,
  .second-section {
    padding: 2rem 4rem !important;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .image-placeholder {
    height: 250px;
  }

  .image-placeholder.small {
    height: 120px;
  }

  .image-placeholder.large {
    height: 250px;
  }
}

/* Responsividade para smartphones (telas pequenas) */
@media (max-width: 767px) {
  .first-section,
  .second-section {
    padding: 2rem 1rem !important;
  }

  .title-cta-reasons {
    font-size: 1.7rem !important;
    padding: 0 3rem !important;
  }

  .desc-cta-reasons {
    padding: 0 3rem !important;
    margin-bottom: 2rem !important;
  }

  .reasons-row {
    padding: 0 2rem !important;
  }
  
  .text-section {
    padding-right: 0;
  }

  .first-title {
    font-size: 2.2rem;
    padding: 0 2rem !important;
  }
  
  .first-description {
    margin-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem !important;
  }

  .title {
    font-size: 2rem;
    text-align: center;
  }

  .description {
    font-size: 0.95rem;
    text-align: center;
  }

  .image-placeholder {
    height: 200px;
  }

  .image-placeholder.small {
    height: 100px;
  }

  .image-placeholder.large {
    height: 200px;
  }

  .third-section {
    flex-direction: column;
    padding: 1rem;
  }

  .space-col {
    width: 100%;
    margin-bottom: 0rem;
    padding: 30px 15px;
  }

  .space-title {
    font-size: 1.2rem;
  }

  .space-text {
    font-size: 0.9rem;
  }

  .image-section {
    padding: 0 2rem !important;
  }

}

/* Geral */
.second-section {
  padding: 2rem 12rem !important;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #617df8;
  margin-bottom: 1rem;
}

.description,
.service-description,
.reason-description {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.5;
}

.service-title,
.reason-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

/* Ícones */
.service-icon,
.reason-icon {
  font-size: 2rem;
  color: #617df8;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
}

/* Listas de serviços e motivos */
.service-list,
.reason-list {
  margin-top: 1.5rem;
}

.service-list .service-title,
.reason-list .reason-title {
  margin-top: 0.75rem;
}

/* Placeholders de imagem */
.image-placeholder {
  border-radius: 12px;
  background-color: #b0b0b0;
  width: 100%;
  height: 300px;
}

.image-placeholder.small {
  width: 100%;
  height: 150px;
}

.image-placeholder.large {
  width: 100%;
  height: 300px;
}

/* Efeitos de hover */

.service-wrapper {
  padding: 0.2rem;
}

.service-content {
  border: 1px #d3d3d3 solid !important;
  box-shadow: none;
  border-radius: 30px;
  padding: 2rem;
}

@media (max-width: 991px) {
  .second-section {
    padding: 2rem 4rem !important;
  }
}

@media (max-width: 767px) {

  .second-section-container {
    padding: 2rem 0 !important;
    width: 100% !important;
    overflow: hidden !important;
  }

  .tip-row {
    margin: 0rem !important;
  }

  .title {
    font-size: 1.8rem;
    text-align: center;
  }

  .description {
    font-size: 0.95rem;
    text-align: center;
  }

  .service-icon,
  .reason-icon {
    font-size: 1.5rem;
  }
}

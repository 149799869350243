
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  overflow-x: hidden !important;
}

.color-default { 
  color: #617DF8 !important;
}
/* Seção Top */
.top-section {
  background-color: #617df8;
  /* Cor de fundo conforme a imagem */
  padding: 8rem 0rem 0rem 0rem !important;
  text-align: center;
}

.section-title {
  color: #ffffff;
  font-size: 1.75rem;
  /* Aumentei um pouco o tamanho da fonte */
  margin-bottom: 2.5rem;
  /* Maior espaço para respirar */
}

/* Caso Item */
.case-item {
  padding: 1.5rem;
  /* Aumentei o padding para melhor espaçamento */
}

/* Estilos para o card da variante */
.variant-card {
  padding: 0.5rem;
  margin-bottom: 20px;
  border-radius: 20px !important;
  background-color: #fff;
  margin-bottom: 2rem !important;
}

/* Container para alinhar a imagem e as informações lado a lado */
.variant-card .row {
  align-items: center;
}

/* Estilos para a imagem da variante */
.variant-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
}

/* Estilos para o título da variante */
.variant-title {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #333;
}

/* Estilos para o botão dentro do card da variante */
.variant-card .produtos-btn {
  background-color: #3150cc;
  color: #fff;
  font-size: 0.8rem !important;
  transition: background-color 0.3s ease;
}

.case-image {
  width: 100%;
  height: 15rem;
  background-color: #fff;
  border-radius: 1rem;
  background-image: url("https://via.placeholder.com/600");
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.case-image:hover {
  transform: scale(1.05);
  /* Adicionado efeito hover para dar destaque */
}

.slick-prev,
.slick-next {
  color: #fff;
  z-index: 1;
}

.slick-dots {
  bottom: -30px;
}

.slick-dot {
  width: 10px;
  height: 10px;
  background-color: #ffc265;
  /* Cor do ponto ativo */
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  opacity: 0.5;
}

.slick-active .slick-dot {
  opacity: 1;
}

/* Responsividade */
@media (max-width: 768px) {
  .case-image {
    height: 10rem;
  }
}

/* Seção do Meio (Middle Section) */
.middle-section {
  background-color: #ffffff;
}

.middle-section h1 {
  color: #617df8;
  font-size: 2.3rem !important;
  font-weight: 700;
  margin-top: 7rem !important;
}

.middle-section h4 {
  font-size: 1.3rem !important;
  font-weight: 600;
  margin-bottom: 3rem !important;
}

.loader-row {
  height: 100vh;
}

.loader-row h1 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

/* Categoria Box */
.category-box {
  width: 100%;
  max-width: 250px;
  height: 250px;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  /* Cursor pointer para indicar interatividade */
}

.category-box:hover {
  transform: scale(1.1);
  /* Maior destaque ao hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  /* Sombra mais intensa no hover */
}

.category-title {
  color: #333;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
}

/* Botões de Categoria */
.category-btn {
  background-color: #617df8 !important;
  color: #fff;
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  border-radius: 2rem !important;
  font-size: 0.9rem;
  box-shadow: none !important;
  transition: 300ms !important;
}

.category-btn.active {
  background-color: #ffc265 !important;
  border-bottom: 0.4rem solid #3332 !important;
}

.category-box img {
  border-radius: 0.6rem;
}

/* Responsividade das Categorias */
@media (max-width: 768px) {
  .category-box {
    max-width: 150px;
    height: 150px;
  }

  .category-title {
    font-size: 0.9rem;
  }

  .category-btn {
    font-size: 0.85rem;
    max-width: 100px;
  }
}

/* Ajuste de overflow */
.category-container {
  overflow: hidden !important;
  padding-bottom: 3rem;
  /* Espaçamento extra na parte inferior */
}

/* Estilos principais da Navbar */
.navbar-custom {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffffb0 !important;
  backdrop-filter: blur(15px);
  border-bottom: 1px solid #617df8;
  padding: 10px 20px;
  box-shadow: none !important;
}

.menu-item .text-container {
  display: inline-flex !important;
  flex-direction: column !important;
  width: 60%;
}

.menu-item .text-container span {
  display: inline-block;
}

.menu-item .text-container .descricao {
  font-size: 0.7rem !important;
  text-align: left;
}

.menu-item .text-container .nome {
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.9rem !important;
}

/* Estilos para a logo */
.navbar-custom .navbar-brand {
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
  margin-right: auto;
}

/* Botão do modal */
.modal-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: transparent; /* Cor de fundo branco */
  border: none;
  border-radius: 50px; /* Borda arredondada */
}

.modal-btn:hover {
  background-color: #f2f2f2; /* Muda a cor ao passar o mouse */
  transform: translateY(-2px); /* Efeito de "elevação" */
}

.modal-btn-icon {
  display: inline-flex !important;
  border-radius: 50%;
  padding: 1.5rem 1.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* Cor branca para o ícone */
  font-size: 1.5rem;
  margin-right: 20px;
  transition: background-color 0.3s ease;
}

.modal-btn-icon:hover {
  background-color: #ff5733; /* Muda a cor do ícone ao passar o mouse */
}

.modal-btn-text {
  display: inline-block !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333; 
  text-align: left !important;/* Cor do texto */
}

.modal-btn-text p {
  font-size: 0.8rem;
  color: #666666; /* Cor para a descrição */
  margin: 5px 0 0;
}

.navbar-brand {
  margin: 0 !important;
}

.navbar-nav-right {
  display: flex;
  align-items: end;
  justify-content: end;
}

/* Itens de navegação à direita */
.navbar-custom .navbar-nav-right .nav-link {
  color: #617df8;
  font-weight: 600;
  transition: color 0.2s;
  font-size: 0.9rem;
  border: solid #617df8 2px;
  border-radius: 10px;
  padding: 0.3rem 0.3rem !important;
  display: flex;
  justify-content: space-betwee !important;
  align-items: center !important;
}

.nav-link-text,
svg {
  display: inline-block !important;
  margin: 0 0.3rem;
}

.nav-item a {
  width: 100%;
}

.nav-item {
  border: solid 0.2rem #0000 !important;
  border-radius: 1rem;
  transition: 300ms;
  margin: 0.2rem 0.5rem !important;
  font-weight: 600 !important;
  font-size: 1.2rem;
}

.nav-item:hover {
  position: relative;
  transform: scale(1.1) !important;
  margin-bottom: 0.8rem;
  z-index: 100 !important;
}

/* Botão de contato à direita */
.navbar-custom .contact-btn {
  background-color: #25d366 !important;
  border-color: transparent !important;
  color: #f5f5f5 !important;
}

.navbar-custom .contact-btn:hover {
  background-color: transparent !important;
  transform: scale(1.1);
  border-color: #25d366 !important;
  color: #25d366 !important;
}

/* Dropdown de produtos */
.dropdown-container {
  position: relative;
  border: none !important;
}

.dropdown-container:hover {
  border: none !important;
  box-shadow: 0px 5px 10px #0000 !important;
}

.dropdown-label {
  text-align: center;
  color: #617df8;
}

.dropdown-label:hover {
  cursor: pointer;
}

.products-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 2rem;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: opacity 0.2s ease, transform 0.2s ease, z-index 0s ease,
    pointer-events 0s;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 50vw;
}

.modal-contact-btn {
  background-color: #617df8 !important;
  width: 50% !important;
  font-size: 1rem !important;
  border-radius: 2rem !important;
  transition: 300ms !important;
}

.modal-contact-btn:hover {
  transform: scale(1.1);
}

.navbar-nav-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Efeito hover e interatividade */
.dropdown-container:hover .products-dropdown,
.products-dropdown:hover {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
  pointer-events: all;
  z-index: 10;
  transition: opacity 0.1s ease, transform 0.1s ease, z-index 0s,
    pointer-events 0s;
}

.menu-btn {
  font-size: 2rem !important;
  background-color: #0000 !important;
  margin: 0 !important;
  padding: 0rem 0rem !important;
  border-radius: 2rem !important;
  box-shadow: none !important;
  color: #617df8 !important;
  font-size: 3rem !important;
}

/* Estilo dos itens do menu */
.menu-item {
  width: auto !important;
  background-color: transparent;
  padding: 1.25rem;
  transition: 300ms;
  display: flex !important;
  justify-content: left;
  align-items: center;
  text-decoration: none;
}

.menu-item:hover {
  transform: scale(1.2);
}

.menu-item svg,
.menu-item span {
  transition: 300ms;
  font-size: 0.9rem;
  margin: 0rem 0.3rem;
}

.menu-item:hover svg {
  transform: scale(1.2) rotateZ(-15deg);
}

.menu-item:hover span {
  transform: scale(1);
  margin-left: 0.2rem;
}

.menu-item span {
  font-size: 1.2rem;
  color: #333;
  padding-left: 0.5rem;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  /* Fundo semitransparente */
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  margin: 1rem 2rem;
  border-radius: 2rem !important;
  box-shadow: none !important;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal */
.modal {
  background-color: white;
  padding: 1rem;
  border-radius: 25px !important;
  width: 90%;
  max-width: 600px;
  text-align: center;
  z-index: 1051;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  z-index: 1052;
}

/* Responsividade */
@media (min-width: 992px) {
  .navbar-nav-right {
    display: flex;
    align-items: center;
  }

  .desktop-hidden {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .navbar-nav-right {
    display: none;
  }

  .desktop-hidden {
    display: inline-block;
  }

  .mobile-hidden {
    display: none !important;
  }

  .navbar-brand-modal {
    margin: 0rem 2rem 2rem 2rem !important;
  }

  .navbar-brand-modal svg {
    width: 64px !important;
  }
}

@media (max-width: 767px) {
  .navbar-custom .navbar-brand {
    font-size: 1rem;
  }

  .navbar-custom .navbar-nav-right .nav-link {
    font-size: 0.8rem;
    margin-right: 8px;
  }

  .contact-btn {
    font-size: 0.8rem !important;
    padding: 0.5rem 1rem !important;
  }
}

.animated-icon {
  transition: transform 0.3s ease;
}

.animated-icon:hover {
  transform: scale(1.2);
}

.index-container {
    padding: 5rem 0rem 0rem 0rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #617df887; /* Mude para a cor desejada */
}

.hero-card{
    width: 100% !important;
    height: auto !important;
    border-radius: 50% !important;
    transition: transform 0.3s ease;
}

.hero-card:hover {
    transform: scale(1.2);
}

.cards-horizontal {
    width: 100%;
}

.categoria-title {
    margin-top: 1rem;
    font-weight: 600;
}

.card-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.ai-inputhero {
    width: 70%;    
    border: solid #617DF8 2px;
    border-radius: 30px;
    margin-top: 2rem !important;
}

.ai-row {
    width: 100% !important;
}

.ai-onboarding-col {
    padding-top: 3rem;
}

.ai-onboarding-col h1{
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
    padding: 0.5rem 4rem !important;
    color: #191919 !important;
}

.ai-onboarding-col p{
    font-weight: 500;
    text-align: center;
    font-size: 1.1rem;
}

.ai-inputhero input {
    outline: none;
    border: none;
    border-radius: 2rem;
    width: 90%;
    margin-left: 10px;
    background-color: transparent !important;
    color: #617DF8 !important;
    font-weight: 400 ;
}

.ai-inputhero input::placeholder{
    color: #8f8f8f !important;
    font-weight: 400 !important ;
}

.ia-error {
    font-weight: 500;
}

.ai-inputhero input:focus {
    outline: none;
    /* Garante que não haverá borda ao focar */
    border: none;
    /* Remove qualquer borda que possa ser aplicada */
}

.input-pesquisar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffff;
    background-color: #617df824 !important;
    border-radius: 30px;
    width: 100%;
}

.input-pesquisar button{
    border-radius: 30px !important;
    background-color: #617DF8 !important;
    height: 100% !important;
    padding: 1rem 0.85rem !important;
}


.banner-menor img {
    border-radius: 0.75rem;
}

.categorias-onboarding-col {
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: center !important;
    padding: 0 2rem !important;
}

.categorias-onboarding-col h1{
    font-weight: 700;
    text-align: left;
    font-size: 1.8rem;
    color: #191919 !important;
}

.categorias-onboarding-col p{
    font-weight: 500;
    text-align: justify;
    font-size: 1rem;
    margin-top: 1rem;
}

@media (max-width: 767px) {
    .ai-onboarding-col h1 {
        padding: 0.5rem 2rem !important;
    }

    .ai-onboarding-col p {
        padding: 0.5rem 2rem !important;
        font-size: 1rem !important;
    }

    .categorias-onboarding-col h1 {
        text-align: center !important;
    }

    .ai-inputhero {
        width: 80% !important;
    }
}
:root {
    --svg-primary-color: #617DF8;
}

.my-svg {
    width: 48px;
    height: auto;
    color: var(--svg-primary-color);
}

.path1,
.path2,
.path3,
.path4,
.path5,
.path6 {
    fill: var(--svg-primary-color);
    /* Cor é definida pela variável CSS */
}
.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .brand-logo {
    width: 100%;
    max-width: 150px; /* Ajuste conforme necessário */
    height: auto;
  }
  
  .placeholder {
    width: 100%;
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  
.sobre-nos-section {
    margin: 2rem 0rem 0rem 0rem;
    padding: 0rem 8rem !important;
    overflow: hidden;
}

.sobre-nos-row {
    height: 100vh;
}

.sobre-title {
    text-align: left !important;
    width: 100% !important;
    /* Centralizar o título */
}

.sobre-text-content h2 {
    font-size: 1.9rem !important;
    font-weight: 700 !important;
    padding-bottom: 1rem;
    color: #191919 !important;
}

.sobre-text-content p {
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    text-align: justify;
    padding-bottom: 1rem !important;
}

.sobre-text-content a {
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    text-align: justify;
    color: #617DF8 !important;
}

.sobre-title h2 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    background: linear-gradient(to right, #FFC265 0%, #617DF8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sobre-description {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.5;
    text-align: center;
    /* Centralizar a descrição */
    margin-bottom: 2rem;
    /* Adiciona espaço abaixo da descrição */
}

.sobre-img img {
    border-radius: 12px;
    max-width: 100%;
}

.sobre-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.sobre-text-wrapper {
    padding-right: 10rem;
}

.sobre-text {
    padding: 0 3rem;
}


.sobre-footer {
    background-color: #FFF;
    padding: 3rem 0;
}

.sobre-footer-item {
    padding: 2rem 1rem;
    text-align: center;
    /* Centralizar o conteúdo de cada item */
}

.icon-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 0 auto 1rem auto;
    transition: all 0.3s ease;
}

.icon-box:hover {
    transform: scale(1.1);
}

.sobre-footer-item h5 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.sobre-footer-item p {
    font-size: 1rem;
    color: #666;
}

/* Versão Responsiva */
@media (max-width: 991px) {
    .sobre-text-wrapper {
        padding: 0 1.5rem;
        /* Ajuste o padding para telas médias */
    }

    .sobre-footer {
        padding: 2rem 0;
        /* Ajuste o padding inferior para telas médias */
    }
}

@media (max-width: 767px) {

    .sobre-img {
        height: auto !important;
        width: 80% !important;
    }

    .sobre-nos-section {
        margin: 2rem 0rem 0rem 0rem;
        padding: 0 !important;
    }

    .sobre-text-content h2 {
        text-align: center !important;
    }
    
    .sobre-text-content p {
        font-size: 0.9rem !important;
        font-weight: 500 !important;
        text-align: justify !important;
    }

    .sobre-text-wrapper {
        padding: 3rem !important;
    }

    .sobre-title {
        font-size: 2rem;
        /* Reduzir o tamanho da fonte do título em dispositivos móveis */
    }

    .sobre-description {
        font-size: 1rem;
        /* Reduzir o tamanho da fonte da descrição em dispositivos móveis */
    }

    .sobre-footer {
        padding: 2rem 0;
        /* Ajuste o padding inferior para dispositivos móveis */
    }

    .sobre-footer-item {
        padding: 1.5rem 0.5rem;
        /* Ajuste o padding dos itens no rodapé para dispositivos móveis */
    }

    .icon-box {
        width: 80px;
        height: 80px;
        /* Reduzir o tamanho da caixa de ícones para dispositivos móveis */
    }

    .sobre-footer-item h5 {
        font-size: 1.1rem;
        /* Ajustar o tamanho da fonte do título em dispositivos móveis */
    }

    .sobre-footer-item p {
        font-size: 0.9rem;
        /* Ajustar o tamanho da fonte do texto em dispositivos móveis */
    }

    .sobre-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 2rem;
    }

    .sobre-text-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .sobre-text-content p {
        text-align: center;
    }

    .sobre-text {
        text-align: center;
    }

    .sobre-text h5 {
        padding-bottom: 2rem;
    }

}
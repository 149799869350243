.cta-section {
    background-color: #FFC265;
    color: #FFF;
    padding: 4rem 4rem;
    position: relative;
    overflow: hidden;
    /* Garantir que as imagens não saiam dos limites da seção */
}

.cta-section h2 {
    font-size: 2.3rem !important;
    font-weight: 700;
    color: #f5f5f5;
    padding: 1rem 2rem !important;
}

.cta-section p {
    font-size: 1rem;
    font-weight: 300;
    color: #f5f5f5;
    font-weight: 600;
    padding: 1rem 2rem;
    margin-bottom: 3rem !important;
}

.cta-text-row {
    height: 60vh !important;
}

.cta-images {
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 80%;
    pointer-events: none;
    /* Impede que as imagens interfiram nas interações do usuário */
    z-index: 1;
    /* Garantir que as imagens fiquem atrás do conteúdo */
}

.cta-image-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: auto;
    height: 60%;
    /* Ajuste conforme necessário */
}

.cta-image-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: auto;
    height: 60%;
    /* Ajuste conforme necessário */
}

.cta-section .text-center {
    position: relative;
    z-index: 2;
    /* Garantir que o texto fique acima das imagens */
}

.shape-top {
    position: relative;
    z-index: 2;
}

.shape-top img {
    width: 100%;
}

.cta-btn {
    background-color: transparent !important;
    color: #f5f5f5 !important;
    font-weight: 700 !important;
    border-radius: 2rem !important;
    font-size: 1.1rem !important;
    border: #f5f5f5 solid 2px !important;
    transition: 300ms !important;
    padding: 1rem 2rem;
}

.cta-btn:hover {
    transform: scale(1.2);
    border-radius: 30px !important;
    color: #FFC265 !important;
    background-color: #f5f5f5 !important;
}

.shape-top img,
.shape-bottom img {
    width: 100%;
}

@media (max-width: 767px) {
    .cta-images img {
        height: 25% !important;
        margin-top: 4rem !important;
    }

    .cta-images {
        left: -13%;
        width: 126%;
    }

    .cta-image-left {
        top: 35%;
    }

    .cta-image-right {
        top: 75%;
    }

    .cta-text-row {
        align-items: center !important;
    }
}

@media (max-width: 1200px) {
    .cta-images img {
        height: 50%;
        margin-top: 2rem;
    }
}
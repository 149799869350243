.footer {
    color: #FFF;
    padding: 1rem 2.5rem;
    border-top: #f5f5f5 solid 1px;
    overflow: hidden;
    position: relative;
}

.footer-logo img {
    max-width: 100px;
}

.footer-links {
    display: flex;
    flex-direction: column;
}

.footer-links h5 {
    margin-bottom: 1rem;
    color: #FFF;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.footer-links ul li {
    margin: 0;
}

.footer-links a {
    transition: 300ms;
}

.footer-links ul li a {
    color: #FFF;
    text-decoration: none;
}

.footer-links a:hover {
    color: #1e1e1e !important;
}

.footer-social {
    display: flex;
    justify-content: flex-end;
}

.social-icons {
    display: flex;
    gap: 1rem;
}

.social-icons a {
    color: #FFC265;
    font-size: 2rem;
    background-color: #FFF;
    padding: 0.5rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
}

.social-icons a:hover {
    transform: scale(1.2);
    color: #FFF;
    background-color: #FFC265;
}

.footer-body {
    padding-top: 1rem;
}

.footer-bottom {
    margin-top: 3rem !important;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.footer-bottom p {
    margin: 0;
}

.footer-bottom a {
    color: #FFF;
    text-decoration: none;
}

.footer-bottom a.ml-3 {
    margin-left: 1.5rem;
}

.text-right {
    display: flex;
    justify-content: end;
}

.text-right a {
    transition: 300ms !important;
}

.text-right a:hover {
    color: #1e1e1e;
}

/* Responsividade aprimorada */
@media (max-width: 991px) {
    .footer {
        padding: 1rem;
        text-align: center;
    }

    .footer-links ul {
        justify-content: center;
        gap: 1rem;
    }

    .footer-social {
        justify-content: center;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-bottom p {
        margin-bottom: 0.5rem;
    }

    .footer-bottom a.ml-3 {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}

@media (max-width: 767px) {
    .contato-section {
        padding: 2rem !important;
    }

    .footer {
        padding: 1rem 0.5rem;
    }

    .footer-logo {
        position: absolute;
        opacity: 0.15;
        max-width: 256px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }

    .footer-logo img {
        max-width: 256px;
        height: auto;
    }

    .footer-links ul {
        align-items: center;
        gap: 0.5rem;
    }

    .footer-links li {
        padding: 0.25rem;
        padding-bottom: 1rem;
    }

    .footer-social {
        justify-content: center;
        margin-top: 1rem;
    }

    .footer-bottom {
        text-align: center;
        padding-top: 0.5rem;
    }

    .footer-bottom p {
        margin-bottom: 0.5rem;
    }

    .footer-bottom a.ml-3 {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}
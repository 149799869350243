/* Início da seção de navbar de dashboard */
.navbarDashboard {
  height: 100%;
  width: 5rem;
  background-color: #1e1e1e;
  background-image: url('../img/dasbhoard-bg.png');
  background-size: cover;
  position: fixed;
  left: 0;
}

.navbarDashboardCol {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navbarIcon {
  width: 48px;
  margin-top: 25px;
  height: 100%;
  color: #FFF;
  transition-duration: 300ms;
}

.navbarIcon:hover {
  color: #617DF8;
  transform: scale(1.5);
  cursor: pointer;
}
/* Fim da seção de navbar de dashboard */

/* Início da seção de dashboard */
.dashboard {
  max-width: 100% !important;
  padding: 0 0 0 5rem !important;
  margin-right: 0 !important;
  display: flex;
  justify-content: center;
}

.dashboardMain {
  flex-direction: column;
  align-items: center;
}

.dashboardMainCards {
  height: 7rem !important;
  width: 15rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  border: 0.2rem solid #e1e1e1 !important;
  transition-duration: 300ms;
  filter: drop-shadow(0 5px 2px #1e1e1e50);
  text-align: center;
}

.dashboardMainCards:hover {
  border-color: #617DF8 !important;
  transform: scale(1.1);
  cursor: pointer;
}

.dashboardCol {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
  text-align: center;
  width: 100%;
  margin: 0 !important;
}

.dashboardCol h6 {
  width: 100%;
}

.dashboardRow {
  padding: 2rem 0 0 0;
  text-align: center;
  margin: 0 !important;
}

.confirmBtn {
  background-color: #617DF8 !important;
  border-radius: 10px !important;
  width: auto !important;
}
/* Fim da seção de dashboard */

/* Início da seção de formulário de dashboard */
.dashboardForm {
  width: 100%;
  display: flex;
  padding: 0 !important;
  flex-direction: column;
  flex-wrap: wrap;
}

.dashboardInput {
  text-align: left !important;
  align-items: start !important;
}

.dashboardForm input,
textarea {
  width: 100%;
  background-color: #fbfbfb;
  border: 0.1rem solid #bfbfbf;
  border-radius: 10px;
  padding: 0.5rem 0.7rem;
  font-size: 0.9rem;
}

.dashboardRadius {
  display: block;
  position: relative;
  user-select: none;
}

.dashboardRadius input {
  position: relative;
  width: 25px;
  height: 10px;
}
/* Fim da seção de formulário de dashboard */

/* Início da seção de pré-visualização de imagem */
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.image-preview {
  display: flex;
  justify-content: center;
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  height: 200px !important;
  transition: 200ms;
  cursor: pointer;
}

.thumbnail-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  max-height: 500px;
  object-fit: cover;
}

.selected-remove-product p {
  font-size: 1rem !important;
}

.image-view-holder {
  display: flex;
  flex-direction: column;
}
/* Fim da seção de pré-visualização de imagem */

/* Início da seção de botão de remoção */
.remove-btn {
  background-color: #FFF !important;
  box-shadow: none !important;
  color: #617DF8 !important;
}

.remove-btn:hover {
  background-color: #617DF8 !important;
  color: #FFF !important;
}
/* Fim da seção de botão de remoção */

/* Início da seção de botão de upload */
.upload-btn {
  border: solid 2px #e1e1e1 !important;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.upload-btn::file-selector-button {
  background-color: #FFF !important;
  box-shadow: none !important;
  color: #617DF8 !important;
  border: none !important;
  border-radius: 10px;
}

.upload-btn::file-selector-button:hover {
  background-color: #617DF8 !important;
  box-shadow: none !important;
  color: #FFF !important;
  border: none !important;
  cursor: pointer;
}
/* Fim da seção de botão de upload */

/* Início da seção de container de thumbnail */
.thumbnail-container {
  border: 2px solid #FFF;
}

.thumbnail-container.selected {
  border: 2px solid #617DF8;
  border-radius: 15px;
  transition: 200ms;
}

.thumbnail-text {
  text-align: center;
  font-weight: bold;
  color: #617DF8;
  padding: 5px;
  border-radius: 5px;
}
/* Fim da seção de container de thumbnail */

/* Adições para lista de produtos e paginação */
/* Card do Produto */
.productCard3 {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  text-align: center;
}

.productCard3:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.productCard3 img {
  border-radius: 8px;
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}

.productCardText2 {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
}

/* Container dos Produtos */
.productDelete {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

/* Paginação */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.pagination-controls button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 1rem;
}

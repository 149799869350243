/* Container principal da galeria */
.gallery-container {
    background-color: #FFF;
    padding: 8rem 0rem 2rem 0rem !important;
}

/* Estilo para o layout "masonry" usando colunas */
.masonry-grid {
    column-count: 5; /* Define o número de colunas */
    column-gap: 15px; /* Espaçamento entre as colunas */
}

.masonry-item {
    margin-bottom: 15px;
    break-inside: avoid;
    overflow: hidden;
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 12px;
    cursor: pointer;
}

.masonry-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Ajustes para telas menores */
@media (max-width: 1200px) {
    .masonry-grid {
        column-count: 4;
    }
}

@media (max-width: 768px) {
    .masonry-grid {
        column-count: 2;
    }
    .gallery-container {
        background-color: #FFF;
        padding: 8rem 2rem 2rem 2rem !important;
    }
    
}

@media (max-width: 480px) {
    .masonry-grid {
        column-count: 1;
    }
}

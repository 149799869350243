/* =================== */
/* SEÇÃO DE LAYOUT GERAL */
/* =================== */

.product-container {
  overflow: hidden;
}

.top-section-container {
  background-color: #ffc265;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0rem 0rem 0rem !important;
}

/* ======================= */
/* CAMPOS DE ENTRADA E BOTÕES */
/* ======================= */

.custom-input-wrapper {
  position: relative;
  width: 80% !important;
  margin: 0 auto;
}

.custom-input {
  width: 100%;
  padding: 1rem;
  padding-left: 1.5rem;
  border-radius: 2rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-size: 1rem;
  color: #666;
}

.custom-input::placeholder {
  color: #999;
  font-weight: 500;
}

.custom-dropdown {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

.dropdown-toggle-custom {
  background-color: #fff;
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #666;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 3rem;
  width: 100%;
}

.dropdown-toggle-custom::after {
  margin-right: 1rem;
  color: #ffc265;
}

.custom-btn {
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 3rem;
  width: 8rem;
  font-weight: bold;
}

.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ======================= */
/* IMAGENS E LAYOUT DE PRODUTOS */
/* ======================= */

.product-image {
  border-radius: 15px !important;
}

.card-product {
  border-radius: 25px !important;
}

.product-title {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 2rem !important;
}

.product-tags {
  margin: 0 !important;
  font-size: 0.6rem !important;
  color: #5a78f0;
  font-style: italic;
  margin-top: 0.5rem !important;
}

.desc-container {
  margin-top: 1rem !important;
}

.desc-container p {
  margin: 0 !important;
  white-space: nowrap;
}

.orc-container {
  border: solid #dfdfdf 1px !important;
  padding: 2rem !important;
  border-radius: 30px;
  min-height: 100% !important;
  width: auto;
}

.orc-container .title-container h3 {
  font-weight: 700;
  font-size: 1.7rem !important;
  color: #5a78f0;
}

.orc-container .title-container p {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.orc-container input,
select {
  margin-top: 0.5rem !important;
  border: solid #c5c5c5 1px !important;
  box-shadow: none !important;
  margin-bottom: 0.7rem !important;
}

.orc-container svg {
  color: #5a78f0 !important;
}

.orc-container label {
  font-size: 0.9rem;
}

.product-thumbnail {
  width: 100%;
  padding-bottom: 100%;
  background-color: #b0b0b0;
  border-radius: 0.75rem;
  background-image: url("https://fakeimg.pl/600x600");
  background-size: cover;
  background-position: center;
}

.product-text-wrapper {
  width: 50% !important;
  padding: 2rem 0rem !important;
}

.produto-button-wrapper button {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  font-size: 1rem;
  box-shadow: none;
}

.produto-button-wrapper .whatsapp {
  background-color: #25d366 !important;
}

.produto-button-wrapper .whatsapp svg {
  color: #f5f5f5 !important;
  font-size: 1.3rem !important;
}

.produto-button-wrapper .email svg {
  color: #5a78f0 !important;
  font-size: 1.3rem !important;
}

.produto-button-wrapper .email {
  background-color: transparent !important;
  border: solid #5a78f0 1px !important;
  color: #5a78f0 !important;
}

.similar-products h1 {
  font-weight: 700;
  text-align: center;
  font-size: 2rem;
  padding: 0.5rem 4rem !important;
  color: #191919 !important;
}

.similar-products p {
  margin-top: 1rem;
  margin-bottom: 5rem;
  font-weight: 500;
  text-align: center;
  font-size: 1.1rem;
}

/* ======================= */
/* SEÇÃO DE ÍCONES */
/* ======================= */

.icon-box {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
  position: relative;
  z-index: 2;
}

.icon-shadow {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  z-index: 1;
}

.text-black {
  color: #000;
}

.text-blue {
  color: #5a78f0;
}

.text-red {
  color: #ff6b6b;
}

.text-green {
  color: #3ecf8e;
}

.ml-3 {
  margin-left: 1rem;
}

/* =================== */
/* IMAGENS E LAYOUT DO CARROSSEL */
/* =================== */

.carousel-section {
  background-color: #ffffff;
  padding: 4rem 0 2rem 0;
  text-align: center;
}

.carousel-section h4 {
  font-weight: 900;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 100%;
  height: var(--height);
  margin-top: -5%;
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
}

.slider .list {
  display: flex;
  width: 100%;
  height: 1.5svh;
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}

.slider .list .item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  animation: autoRun 30s linear infinite;
  transition: filter 0.5s;
  animation-delay: calc(
    (2s / var(--quantity)) * (var(--position) - 1)
  ) !important;
}

.slider .list .item:first-child {
  animation-delay: 0s !important; /* Garante que o primeiro item não tenha atraso */
}

.slider .list .item img {
  width: 100%;
  padding: 1rem;
  margin: 0rem;
  height: auto;
}

.carousel-text {
  padding-bottom: 2rem;
}

.carousel-text h2 {
  font-size: 2rem !important;
  font-weight: 700;
  color: #191919;
}

.carousel-text p {
  font-size: 1rem;
  padding-top: 1rem;
  font-weight: 500;
}

.item img {
  overflow: visible !important;
}

@keyframes autoRun {
  from {
    left: 100%;
  }

  to {
    left: calc(var(--width) * -1);
  }
}

.slider:hover .item {
  animation-play-state: paused !important;
  filter: grayscale(1);
}

.slider .item:hover {
  filter: grayscale(0);
}

.slider[reverse="true"] .item {
  animation: reversePlay 50s linear infinite;
}

@keyframes reversePlay {
  from {
    left: calc(var(--width) * -1);
  }

  to {
    left: 100%;
  }
}

/* =================== */
/* TAMANHOS DE IMAGENS */
/* =================== */

.large-image {
  width: 15rem;
  height: 15rem;
}

.small-image {
  width: 8rem;
  height: 8rem;
}

/* =================== */
/* WRAPPERS E ESPAÇAMENTOS */
/* =================== */

.produto-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.produto-button-wrapper button {
  text-transform: initial;
  border-radius: 11px;
}

/* =================== */
/* NOVO ESTILO PARA EXIBIÇÃO EM LINHA DOS PRODUTOS */
/* =================== */

.similar-products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
}

.similar-product-item {
  flex-basis: 23%; /* Mostra até 4 itens por linha */
  margin-bottom: 2rem;
}

.similar-product-image {
  width: 100%;
  height: 12rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

/* Ajustes para telas menores */
@media (max-width: 1024px) {
  .similar-product-item {
    flex-basis: 32%; /* Mostra até 3 itens por linha em telas médias */
  }
}

@media (max-width: 768px) {
  .similar-products h1 {
    padding: 0.5rem 2rem !important;
  }

  .similar-products p {
    padding: 0 2rem;
    margin-bottom: 3rem !important;
  }

  .card-product {
    margin-bottom: 5.5rem !important;
  }

  .produto-button-wrapper button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .orc-container {
    padding: 1.5rem !important;
  }

  .orc-container .title-container h3 {
    font-size: 1.4rem !important;
  }

  .orc-container .title-container p {
    font-size: 0.9rem !important;
    margin: 1rem 0 !important;
    margin-bottom: 2rem !important;
  }

  .product-tags {
    padding-bottom: 2rem !important;
  }

  .similar-product-item {
    flex-basis: 48%; /* Mostra até 2 itens por linha em telas menores */
  }

  .carousel-text {
    padding-bottom: 2rem;
  }

  .carousel-text h2 {
    padding: 0 3rem 1rem 3rem !important;
    font-size: 1.6rem !important;
  }

  .carousel-text p {
    font-size: 0.9rem !important;
    padding: 0 2rem 0rem 2rem !important;
  }
}

@media (max-width: 576px) {
  .similar-product-item {
    flex-basis: 100%; /* Mostra 1 item por linha em telas muito pequenas */
  }
}

@media (max-width: 767px) {
  .carousel-text p {
    font-size: 1.5rem;
    padding: 0rem 0rem 0rem 0rem;
  }
}

/* ==================== SEÇÃO PRINCIPAL ==================== */
.products-section {
    padding: 2rem 0;
    background-color: #FFC265;
    /* Cor amarela da paleta */
}

/* ==================== INPUT DE PESQUISA ==================== */

.input-pai {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-pai .product-selection-input {
    width: 80%;
    background-color: #5A78F0 !important;
    margin-bottom: 20px;
    border-radius: 2rem;
    border-bottom: 0.30rem solid #1e1e1e50;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-pesquisar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    border-radius: 2rem;
    width: 100%;
}

.product-selection-input select {
    outline: none;
    border: none;
    background-color: #ffffff;
    width: 100%;
}

/* ==================== CONTEÚDO DE PRODUTOS ==================== */

.products-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

/* Imagem do Produto */
.product-image-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-image-wrapper {
    background-color: #cccccc;
    border-radius: 15px;
    padding: 2rem;
}

.product-image {
    border-radius: 15px;
    max-width: 100%;
    height: auto;
}

/* Formulário de Produto */
.product-form-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card {
    background-color: transparent !important;
    border-radius: 15px;
    width: 80%;
}

/* Estilização das Linhas do Formulário */
.form-row {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-row-cta {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form-toggle {
    width: 100%;
    padding: 0.75rem;
    border-radius: 10px;
    border: none;
    background-color: #f1f1f1;
    font-size: 1rem;
    font-weight: bold;
    color: #222324bd;
}

/* Botões de Ação */
.produtos-btn {
    color: #f5f5f5 !important;
    font-size: 0.9rem !important;
    border-radius: 11px !important;
    width: 100%;
    display: block;
    margin-top: 1.5rem;
    padding: 0.7rem 2rem !important;
    text-align: center !important;
}

.produtos-btn:hover {
    border-bottom: #1e1e1e50 solid 0.3rem !important;
}

/* ==================== SEÇÃO DE ÍCONES ==================== */

.icons-section {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icons-section .icon-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem;
}

.icons-section .icon-img {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    border-radius: 10px;
}

.icons-section h4 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.icons-section p {
    color: #555;
    margin: 0;
}

/* ==================== SHAPE INFERIOR ==================== */
.shape-bottom {
    transform: translateY(-1px);
    width: 100%;
}

.shape-bottom img {
    width: 100%;
}


/* ==================== CARROSSEL DE IMAGENS ==================== */

.carousel-wrapper {}

.carousel-item-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column !important;
    padding: 2rem;
    height: 15rem !important;
}

.carousel-item-wrapper img {
    height: 15rem;
    width: 15rem;
    border-radius: 1rem;
}

/* ==================== SOBRE NÓS ==================== */

.about-section {
    padding: 4rem 15% !important;
}

.about-image-col {
    padding-right: 30px;
}

.about-text-col {
    padding-left: 30px;
}

.about-title {
    font-size: 2rem;
    font-weight: bold;
    color: #222324;
}

.about-description {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.5;
}
.contato-section {
    background-color: #FFC265;
    padding: 4rem 0;
}

.contato-section h2 {
    color: #FFF;
}

.text-left {
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.MDBBtn {
    margin-top: 1rem;
}

.form-contato {
    border-radius: 30px  !important;
    background-color: #f5f5f5  !important;
    box-shadow: none !important;
}

.form-contato label{
    height: 1rem !important;
    font-weight: 600;
    padding-left: 0.1rem;
    padding-bottom: 1rem !important;
    color: #FFC265 !important;
}

.form-contato input, textarea{
    border-radius: 10px  !important;
    border: solid 1px #bababa !important;
    display: block;
    width: 100%;
    min-height: 2.5rem !important;
    padding: 0.5rem 0.5rem;
    background-color: #fefefe;
}

.contato-form-wrapper {
    background-color: #FFC265 !important;
    border: solid 2px #f5f5f5;
    border-radius: 30px;
    padding: 3rem 2rem !important;
}

.contato-form-wrapper h2 {
    background-color: #FFF !important;
    border-radius: 2rem !important;
}

.contato-form-wrapper input {
    background-color: #FFF !important;
    border-radius: 2rem !important;
}

/* Estilos para os inputs quando estão selecionados */
.contato-form-wrapper input:focus {
    border-color: #FF8C00 !important;
    /* Altera a cor da borda quando o input é selecionado */
    box-shadow: 0 0 0.5rem rgba(255, 140, 0, 0.5);
    /* Adiciona uma sombra laranja ao redor do input */
    outline: none;
    /* Remove o outline padrão do navegador */
}

/* Estilos adicionais para remover bordas internas */
.form-outline .form-control~.form-notch div {
    border: none !important;
}

.contato-onboarding-form h2{
    font-weight: 700;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}

.contato-onboarding-form p{
    font-weight: 500;
    text-align: justify;
}

.contato-links {
    display: flex !important;
    padding-top: 1rem;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}

.contato-links a{
    display: inline-block;
    padding: 0.7rem 1rem;
    border-radius: 11px;
}

.contato-links .whatsapp{
    background-color: #25d366 !important;
    color: #f5f5f5 !important;
    font-weight: 600 !important;
    width: 50% !important;
}

.contato-links .telefone{
    background-color: #617DF8 !important;
    color: #f5f5f5 !important;
    font-weight: 600 !important;
    width: 48% !important;
}

.contato-links .mail{
    background-color: #f5f5f5 !important;
    color: #FFC265  !important;
    font-weight: 600 !important;
    width: 100% !important;
}

.contato-links .whatsapp:hover{
    background-color: #f5f5f5 !important;
    color: #25d366 !important;

}

.contato-links .mail:hover{
    background-color: #FFC265  !important;
    color: #f5f5f5  !important;
    border: solid 2px #f5f5f5 !important;
}

.contato-links .telefone:hover{
    background-color: #f5f5f5 !important;
    color: #617DF8 !important;

}

.contato-btn {
    background-color: #FFC265 !important;
    box-shadow: none !important;
    color: #f5f5f5 !important;
    border-radius: 2rem !important;
    font-weight: 600 !important;
}

.contato-btn:hover{
    background-color: transparent !important;
    border: solid 1px #FFC265 !important;
    color: #FFC265 !important;
}

.contato-btn-back {
    background-color: #FFF !important;
    box-shadow: none !important;
    color: #1e1e1e !important;
    border-radius: 2rem !important;
    font-weight: 600 !important;
    margin-right: 1rem !important;
}


.form-check-label {
    font-size: 0.8rem;
    font-weight: 500 !important;
    padding-left: 0.3rem !important;
}

.form-step form {
    background-color: #FFC265;
    box-shadow: none !important;
    padding: 4rem 2rem;
}

.form-step {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
    transform: translateX(100%);
    background-color: #FFC265 !important;
}

.form-step.active {
    opacity: 1;
    transform: translateX(0);
}

.form-step.inactive {
    opacity: 0;
    transform: translateX(-100%);
}

@media (max-width: 767px) {
    .contato-onboarding-form {
        margin-bottom: 2rem;
    }
}
/* ==================== SHAPE SUPERIOR ==================== */
.shape-top {
    padding-top: 2rem;
    transform: translateY(1px);
}

.shape-bottom {
    padding-bottom: 2rem;
    transform: translateY(-1px);
}

.shape-top,
.shape-bottom {
    width: 100%;
}

.shape-top img,
.shape-bottom img {
    width: 100%;
}

/* ==================== FIM SHAPE SUPERIOR ==================== */

/* ==================== SEÇÃO DE PRODUTOS ==================== */
.product-gen-container {
    margin-top: 1.5rem !important;
}

.produtos-section {
    padding-top: 0;
    padding-bottom: 0;
    background-image: url('../img/index/produtos-bg.svg');
    /* Caminho para sua imagem de fundo */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #f5f5f5;
}

.produtos-section h2 {
    color: #f5f5f5;
    font-weight: 600 !important;
}

.produtos-section h4 {
    color: #f5f5f5;
}

.produtos-section h2,
.produtos-section h4,
.produtos-section .mdb-tabs,
.produtos-section .mdb-tabs-content,
.produtos-section .text-center {
    padding: 2rem 0;
}

/* ==================== FIM SEÇÃO DE PRODUTOS ==================== */

/* ==================== ESTILIZAÇÃO DOS CARDS ==================== */

.card-pai {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 60%;
}

.product-col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;  
}

.produtos-section .produtos-card {
    background-color: #f5f5f5;
    /* Leve transparência para os cards */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
}

.produtos-card-ai {
    background-color: #f5f5f5;
    border-radius: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    box-shadow: none !important;
    border: solid 1px #617DF8 !important;
}

.produto-title {
    margin-top: 1rem;
    color: #617df8;
    font-weight: 700;
    text-align: left !important;
    width: 100% !important;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.product-tags {
    color:rgba(28, 41, 51, 0.356);
    font-weight: 600;
    font-size: 0.6rem;
    margin-top: 1rem !important; 
    display: block;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-top: 0.5rem;
}

.product-img {
    border-radius: 11px !important;
}

/* ==================== FIM ESTILIZAÇÃO DOS CARDS ==================== */

/* ==================== ESTILIZAÇÃO DAS TABS ==================== */
.produtos-section .mdb-tabs-link {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.produtos-section .mdb-tabs-link.active {
    background-color: #08CFB0;
    /* Cor ativa da tab */
}

/* ==================== FIM ESTILIZAÇÃO DAS TABS ==================== */

/* ==================== ESTILIZAÇÃO DO BOTÃO ==================== */
.produtos-section .mdb-btn {
    background-color: #08CFB0;
    border: none;
    border-radius: 50px;
    padding: 0.75rem 2rem;
}

.produtos-btn {
    color: #364eb9 !important;
}

/* ==================== FIM ESTILIZAÇÃO DO BOTÃO ==================== */

/* ==================== ESTILIZAÇÃO DOS TÍTULOS ==================== */
.produtos-section h2 {
    padding: 0rem 0rem 1rem 0rem !important;
    margin: 0 !important;
}

.produtos-section h4 {
    padding: 0rem 0rem 2rem 0rem !important;
    margin: 0 !important;
    font-weight: 400 !important;
}

/* ==================== FIM ESTILIZAÇÃO DOS TÍTULOS ==================== */

/* ==================== ESTILIZAÇÃO DAS TABS (HOVER) ==================== */
.produtos-tab {
    background-color: #f5f5f5 !important;
    color: #617DF8 !important;
    font-size: 0.9rem !important;
    text-transform: initial !important;
    text-align: center !important;
    border-radius: 12px !important;
}

.produtos-tab:hover {
    background-color: transparent!important;
    color: #f5f5f5 !important;
}


.produtos-tab-v2 {
    background-color: #f5f5f5 !important;
    color: #4969f8 !important;
    font-size: 0.9rem !important;
    text-transform: initial !important;
    text-align: center !important;
    border-radius: 12px !important;
}

.produtos-tab-v2:hover {
    background-color: transparent!important;
    color: #364eb9 !important;
}


/* ==================== FIM ESTILIZAÇÃO DAS TABS (HOVER) ==================== */

/* ==================== ESTILIZAÇÃO DOS ITEMS ==================== */
.nav-item {
    border-bottom: #FFF0 solid 0.1rem !important;
    border-radius: none !important;
    transition: 300ms !important;
}

/* ==================== FIM ESTILIZAÇÃO DOS ITEMS ==================== */

/* ==================== ESTILIZAÇÃO DO BOTÃO "VER TODOS" ==================== */
.produtos-btn {
    background-color: #617DF8 !important;
    color: #617DF8 !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    padding: 0.35rem 3rem !important;
    border-radius: 0.3rem !important;
    transition: 300ms !important;
}

.produtos-btn:hover {
    border-bottom: #1e1e1e50 solid 0.3rem !important;
}

.all-produtos-btn {
    background-color: transparent !important;
    color: #f5f5f5 !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    padding: 1rem 1rem !important;
    border-radius: 11px !important;
    border: solid 2px #f5f5f5 !important;
    text-transform: initial !important;
}



/* ==================== FIM ESTILIZAÇÃO DO BOTÃO "VER TODOS" ==================== */


@media (max-width: 767px) {
    .produtos-section h2{
        padding-top: 2rem !important;
    }

    .produtos-section h4{
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
}
/* Cor #617DF8 - Azul */
.bg-blue {
    background-color: #617DF8 !important;
  }
  
  /* Cor #FF5733 - Laranja */
  .bg-orange {
    background-color: #FF5733;
  }
  
  /* Cor #33C1FF - Azul Claro */
  .bg-light-blue {
    background-color: #33C1FF;
  }
  
  /* Cor #FFC300 - Amarelo */
  .bg-yellow {
    background-color: #FFC300;
  }
  
  /* Cor #8E44AD - Roxo */
  .bg-purple {
    background-color: #8E44AD;
  }
  
  /* Cor #2ECC71 - Verde */
  .bg-green {
    background-color: #2ECC71;
  }
  
  /* Cor WhatsApp */
.whatsapp-color {
    background-color: #25D366; /* Verde do WhatsApp */
    color: white; /* Texto em branco para contraste */
  }
  